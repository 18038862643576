import Link from "next/link";
import { useCollection } from "react-firebase-hooks/firestore";
import {
  getFirestore,
  collection,
  firestore,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {
  PopularContainer,
  PopularH2,
  PopularList,
  NoBlogsFound,
  PopularItem,
  PopularTitle,
  PopularP,
} from "./PopularElements";

const Popular = () => {
  const [posts, postsLoading, postsError] = useCollection(
    query(
      collection(getFirestore(firestore), "fl_content"),
      where("type", "==", "blogPost"),
      where("publish", "==", true),
      orderBy("date", "desc")
    ),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  return (
    <PopularContainer id="popular">
      <PopularH2>Most Popular</PopularH2>
      <PopularList>
        {postsError && (
          <NoBlogsFound>Error: {JSON.stringify(postsError)}</NoBlogsFound>
        )}
        {postsLoading && (
          <>
            <SkeletonTheme>
              <NoBlogsFound>
                <Skeleton width={150} />
                <Skeleton />
                <Skeleton />
              </NoBlogsFound>
              <NoBlogsFound>
                <Skeleton width={150} />
                <Skeleton />
                <Skeleton />
              </NoBlogsFound>
              <NoBlogsFound>
                <Skeleton width={150} />
                <Skeleton />
                <Skeleton />
              </NoBlogsFound>
            </SkeletonTheme>
          </>
        )}
        {posts && (
          <>
            {posts.docs.slice(1, 4).map((post) => (
              <PopularItem key={post.data().id}>
                <Link href="/blog/[id]" as={"/blog/" + post.data().id}>
                  <PopularTitle id="blog-title">
                    {post.data().title}
                    <PopularP>{post.data().summary}</PopularP>
                  </PopularTitle>
                </Link>
              </PopularItem>
            ))}
          </>
        )}
      </PopularList>
    </PopularContainer>
  );
};

export default Popular;
