import styled from "styled-components";

export const PopularContainer = styled.section`
  z-index: 3;
  position: relative;
  margin: 0 auto 20px auto;
  display: flex;
  flex-direction: column;
  align-items: left;
`;

export const PopularH2 = styled.h2`
  font-size: 2.25rem;
  text-align: left;
  margin-top: 16px;
  margin-bottom: -10px;

  @media screen and (max-width: 768px) {
    margin-top: -20px;
  }

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const PopularList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0 auto 0 0;
  min-height: 300px;
`;

export const NoBlogsFound = styled.li`
  margin: 14px auto;
  padding: 10px 0 5px 0;
  width: 700px;

  @media screen and (max-width: 768px) {
    padding: 4px 0;
    margin: 18px 0;
    width: 420px;
  }

  @media screen and (max-width: 480px) {
    width: 315px;
  }
`;

export const PopularItem = styled.li`
  margin: 14px auto;
  padding: 10px 0 5px 0;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    padding: 4px 0;
    margin: 18px 0;
    width: 100%;
  }

  @media screen and (max-width: 480px) {
    width: 95%;
  }
`;

export const PopularTitle = styled.span`
  cursor: pointer;
  font-weight: 600;
  font-size: 1.375rem;

  @media screen and (max-width: 768px) {
    font-size: 1.25rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1rem;
  }
`;

export const PopularP = styled.span`
  margin-top: 2px;
  display: block;
  font-weight: 400;
  font-size: 1rem;
`;
